import { DirectionProvider } from "@radix-ui/react-direction";

import { type LoaderFunctionArgs, type LinksFunction } from "@remix-run/node";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigation,
  useRouteLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import "./tailwind.css";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import minMax from "dayjs/plugin/minMax";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import NProgress from "nprogress";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next/react";
import { Toaster } from "./components/ui/sonner";
import { getEnv } from "./lib/env.server";
import { i18nCookie } from "./services/cookie";
import { remixI18n } from "./services/i18n.server";
import { defaultTimezone } from "./constants/timezone";

// extend dayjs plugins for the entire app here
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.tz.setDefault(defaultTimezone);

export async function loader({ request }: LoaderFunctionArgs) {
  const [locale, ENV] = await Promise.all([
    remixI18n.getLocale(request),
    getEnv(),
  ]);

  return json(
    {
      locale,
      ENV,
    },
    {
      headers: {
        "Set-Cookie": await i18nCookie.serialize(locale),
      },
    },
  );
}

export let handle = {
  i18n: ["common"],
};

export const links: LinksFunction = () => {
  return [
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossOrigin: "anonymous",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap",
    },
    {
      rel: "stylesheet",
      href: "https://cdnjs.cloudflare.com/ajax/libs/nprogress/0.2.0/nprogress.min.css",
      type: "text/css",
    },
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>("root");
  const { i18n } = useTranslation();
  const navigation = useNavigation();

  useEffect(() => {
    NProgress.configure({ parent: "#ngProgress", showSpinner: false });
    if (navigation.state === "idle") {
      NProgress.done();
    } else {
      NProgress.start();
    }
  }, [navigation.state]);
  useChangeLanguage(data?.locale || "en");

  const dir = i18n.dir();

  return (
    <html lang={data?.locale || "en"} dir={dir}>
      <head>
        <Links />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
      </head>
      <body>
        <div id="ngProgress" style={{ overflow: "unset", zIndex: "1300" }} />
        <DirectionProvider dir={dir}>
          <Toaster />
          {children}
        </DirectionProvider>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.ENV)}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
